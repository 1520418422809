export enum KEYBOARD_KEYCODE {
  enter = 'Enter',
  escape = 'Escape',
  backspace = 'Backspace',
  delete = 'Delete',
  shift = 'Shift'
}

export enum LOGIN_FORM_DATA {
  username = 'username',
  password = 'password'
}

export enum APP_ROUTE_LOCATION {
  dashboard = 'dashboard',
  alerts = 'alerts',
  events = 'events'
}

export enum COMMON_CONFIRM_MODAL_TYPE {
  error = 'error'
}

export enum BUTTON_BIG_TYPE {
  primary = 'primary',
  secondary = 'secondary',
  destructive = 'destructive'
}

export enum BUTTON_SMALL_TYPE {
  primary = 'primary',
  secondary = 'secondary',
  destructive = 'destructive'
}

export enum COMPANY_FILTER_ID {
  all = 'all'
}

export enum PIN_BTN_TYPE {
  pin = 'pin',
  unpin = 'unpin'
}

export enum TOOLTIP_POSITION {
  topLeft = 'top_left',
  rightCenter = 'right_center'
}

export enum AVATAR_SIZE {
  large = 'large',
  medium = 'medium',
  small = 'small'
}

export enum NODE_STATUS {
  ok = 'ok',
  warning = 'warning',
  critical = 'critical'
}

export enum NODE_POWER_SUPPLY {
  ok = 'ok',
  failed = 'failed'
}

export enum NODE_BATTERY_STATUS {
  ok = 'ok',
  failed = 'failed'
}

export enum NODE_FAN_STATUS {
  ok = 'ok',
  unknown = 'unknown'
}

export enum ADAPTER_STATUS {
  up = 'up',
  down = 'down',
  warning = 'warning'
}

export enum ADAPTER_ASSIGNMENT {
  management = 'management',
  data = 'data',
  storage = 'storage'
}

export enum LOADER_TYPE {
  authorization = 'authorization',
  widget = 'widget'
}

export enum DATE_FORMAT {
  EUROPE = 'EUROPE',
  JAPAN = 'JAPAN',
  USA = 'USA'
}

export enum TIME_FORMAT {
  TWENTY_FOUR = 'CLOCK_24_HOUR',
  TWELVE = 'CLOCK_12_HOUR'
}

export enum SELECTBOX_MENU_PLACEMENT {
  bottom = 'bottom',
  top = 'top'
}
