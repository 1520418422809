import { CLUSTER_RESPONSE_KEYS } from './clusters';
import { ACCOUNT_AVATAR_KEYS } from './account';
import { ALERTS_COUNT_RESPONSE_KEYS } from './alerts';

export const ORG_RESPONSE_KEYS = {
  ROWS: 'rows',
  ROWS_FIELD: {
    ID: 'id',
    NAME: 'name',
    ALERTS_COUNT: 'alertsCount',
    ALERTS_COUNT_FIELD: {
      CRITICAL: ALERTS_COUNT_RESPONSE_KEYS.CRITICAL,
      WARNING: ALERTS_COUNT_RESPONSE_KEYS.WARNING
    },
    CLUSTER_COUNT: 'clustersCount',
    NODES_COUNT: 'nodesCount',
    BUCKETS_COUNT: 'bucketsCount',
    NETWORKS_COUNT: 'networksCount',
    CLUSTERS: 'clusters',
    CLUSTERS_FIELD: {
      ID: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.ID,
      NAME: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.NAME,
      COMPANY_NAME: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.COMPANY_NAME,
      STATUS: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.STATUS,
      VERSION: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.VERSION,
      LOCATION: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.LOCATION,
      CAPACITY: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.CAPACITY,
      BACKUP: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.BACKUP,
      GENERIC: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.GENERIC,
      ALLOCATED: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.ALLOCATED
    },
    USERS: 'users',
    USERS_FIELD: ACCOUNT_AVATAR_KEYS
  }
} as const;
