import { APP_ROUTE_LOCATION } from 'commonExtensions/constants';
import { ALERT_SEVERITY, ALERTS_SOURCE, ALERTS_STATUS } from 'constants/alerts';
import { CLUSTER_STATUS, CLUSTERS_TABLE_KEYS } from 'constants/clusters';
import locale from 'locale';

export const navigationTabLocale = {
  [APP_ROUTE_LOCATION.dashboard]: locale('IDS_NAVIGATION_TAB_DASHBOARD_TITLE'),
  [APP_ROUTE_LOCATION.alerts]: locale('IDS_NAVIGATION_TAB_ALERTS_TITLE'),
  [APP_ROUTE_LOCATION.events]: locale('IDS_NAVIGATION_TAB_EVENTS_TITLE')
};

export const alertSeverityLocale = {
  [ALERT_SEVERITY.critical]: locale('IDS_ALERTS_SEVERITY_CRITICAL'),
  [ALERT_SEVERITY.warning]: locale('IDS_ALERTS_SEVERITY_WARNING'),
  [ALERT_SEVERITY.ok]: locale('IDS_ALERTS_SEVERITY_OK')
};

export const alertSourceLocale = {
  [ALERTS_SOURCE.bucket]: locale('IDS_ALERTS_SOURCE_BUCKET'),
  [ALERTS_SOURCE.cluster]: locale('IDS_ALERTS_SOURCE_CLUSTER'),
  [ALERTS_SOURCE.disk]: locale('IDS_ALERTS_SOURCE_DISK'),
  [ALERTS_SOURCE.network]: locale('IDS_ALERTS_SOURCE_NETWORK'),
  [ALERTS_SOURCE.node]: locale('IDS_ALERTS_SOURCE_NODE')
};

export const alertStatusLocale = {
  [ALERTS_STATUS.solved]: locale('IDS_ALERTS_STATUS_SOLVED'),
  [ALERTS_STATUS.inProgress]: locale('IDS_ALERTS_STATUS_IN_PROGRESS')
};

export const clusterStatusLocale = {
  [CLUSTER_STATUS.ok]: locale('IDS_CLUSTERS_STATUS_OK'),
  [CLUSTER_STATUS.warning]: locale('IDS_CLUSTERS_STATUS_WARNING'),
  [CLUSTER_STATUS.critical]: locale('IDS_CLUSTERS_STATUS_CRITICAL')
};

export const clustersTableColumnName = {
  [CLUSTERS_TABLE_KEYS.NAME]: locale('IDS_CLUSTERS_TABLE_COLUMN_NAME'),
  [CLUSTERS_TABLE_KEYS.COMPANY_NAME]: locale('IDS_CLUSTERS_TABLE_COLUMN_COMPANY'),
  [CLUSTERS_TABLE_KEYS.BUCKETS_COUNT]: locale('IDS_CLUSTERS_TABLE_COLUMN_BUCKETS'),
  [CLUSTERS_TABLE_KEYS.NODES_COUNT]: locale('IDS_CLUSTERS_TABLE_COLUMN_NODES'),
  [CLUSTERS_TABLE_KEYS.VERSION]: locale('IDS_CLUSTERS_TABLE_COLUMN_VERSION'),
  [CLUSTERS_TABLE_KEYS.LAST_UPDATE]: locale('IDS_CLUSTERS_TABLE_COLUMN_LASTUPDATE'),
  [CLUSTERS_TABLE_KEYS.STORAGE]: locale('IDS_CLUSTERS_TABLE_COLUMN_STORAGE'),
  [CLUSTERS_TABLE_KEYS.STATUS]: locale('IDS_CLUSTERS_TABLE_COLUMN_STATUS'),
  [CLUSTERS_TABLE_KEYS.ALERTS]: locale('IDS_CLUSTERS_TABLE_COLUMN_ALERTS')
};
