import { ACCOUNT_AVATAR_KEYS, ACCOUNT_RESPONSE_KEYS } from 'api/models/account';

export const ACCOUNT_AVATAR_DATA = {
  ...ACCOUNT_AVATAR_KEYS,
  INITIALS: 'initials'
} as const;

export const ACCOUNT_TABLE_SETTINGS = {
  ID: ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.TABLE_FIELD.ID,
  SHOW_PER_PAGE: ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.TABLE_FIELD.SHOW_PER_PAGE,
  COLUMNS: ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.TABLE_FIELD.COLUMNS,
  COLUMNS_FIELD: {
    ID: ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.TABLE_FIELD.COLUMNS_FIELD.ID,
    COLUMN_WIDTH: ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.TABLE_FIELD.COLUMNS_FIELD.COLUMN_WIDTH
  }
} as const;
