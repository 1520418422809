import { ALERTS_COUNT_RESPONSE_KEYS } from './alerts';

export const NETWORKS_COUNT_RESPONSE_KEYS = {
  COUNT: 'count',
  ALERTS_COUNT: 'alertsCount',
  ALERTS_COUNT_FIELD: {
    CRITICAL: ALERTS_COUNT_RESPONSE_KEYS.CRITICAL,
    WARNING: ALERTS_COUNT_RESPONSE_KEYS.WARNING
  }
} as const;

export const NETWORK_RESPONSE_KEYS = {
  ROWS: 'rows',
  ROWS_FIELD: {
    ID: 'id',
    NODE_NAME: 'nodeName',
    STATUS: 'status',
    ADAPTERS: 'adapters',
    ADAPTERS_FIELD: {
      ID: 'id',
      NAME: 'name',
      STATUS: 'status',
      IP_ADDRESS: 'ipAddress',
      BANDWIDTH: 'bandwidth',
      SPEED: 'speed',
      MTU: 'mtu',
      ASSIGNMENT: 'assignment',
      ALERTS_COUNT: 'alertsCount',
      ALERTS_COUNT_FIELD: {
        CRITICAL: ALERTS_COUNT_RESPONSE_KEYS.CRITICAL,
        WARNING: ALERTS_COUNT_RESPONSE_KEYS.WARNING
      }
    }
  }
} as const;
