import { IAlertsCount, IAlertsSeverityArr, ITableBody } from 'types';
import { ALERT_SEVERITY } from 'constants/alerts';
import locale from 'locale';

export const getInitials = (str: string) => {
  return str
    ? str
        .split(' ')
        .map((word) => word.charAt(0))
        .join('')
    : '';
};

export const getAlertsCountArr = (data: IAlertsCount): IAlertsSeverityArr[] => {
  const arr = [];

  for (const key in data) {
    if (data[key as keyof IAlertsCount]) {
      arr.push({
        id: key,
        value: data[key as keyof IAlertsCount]
      });
    }
  }

  if (arr.length === 0) {
    arr.push({
      id: ALERT_SEVERITY.ok,
      value: locale('IDS_ALERTS_SEVERITY_OK')
    });
  }

  return arr;
};

type DeepComparable =
  | ITableBody
  | string
  | number
  | boolean
  | null
  | undefined
  | { [key: string]: DeepComparable }
  | DeepComparable[];

const arrDeepEqual = <T extends DeepComparable>(a: T, b: T): boolean => {
  if (a === b) return true;

  if (typeof a !== typeof b) return false;

  if (typeof a === 'object' && a !== null && typeof b === 'object' && b !== null) {
    if (Array.isArray(a) !== Array.isArray(b)) return false;

    if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) return false;
      for (let i = 0; i < a.length; i++) {
        if (!arrDeepEqual(a[i], b[i])) return false;
      }
      return true;
    } else {
      const keysA = Object.keys(a) as (keyof typeof a)[];
      const keysB = Object.keys(b) as (keyof typeof b)[];

      if (keysA.length !== keysB.length) return false;

      for (const key of keysA) {
        if (!keysB.includes(key as keyof typeof b)) return false;

        const valueA = (a as { [key: string]: DeepComparable })[key as string];
        const valueB = (b as { [key: string]: DeepComparable })[key as string];

        if (!arrDeepEqual(valueA, valueB)) return false;
      }
      return true;
    }
  }

  return false;
};

export const areArraysEqual = <T extends DeepComparable>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (!arrDeepEqual(arr1[i], arr2[i])) return false;
  }

  return true;
};
