import { CLUSTER_RESPONSE_KEYS } from 'api/models/clusters';

export const CLUSTERS_TABLE_KEYS = {
  ID: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.ID,
  NAME: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.NAME,
  COMPANY_NAME: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.COMPANY_NAME,
  COMPANY_ID: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.COMPANY_ID,
  BUCKETS_COUNT: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.BUCKETS_COUNT,
  NODES_COUNT: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.NODES_COUNT,
  VERSION: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.VERSION,
  LAST_UPDATE: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.LAST_UPDATE,
  GET_LAST_UPDATE: 'getLastUpdate',
  CAPACITY: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.CAPACITY,
  BACKUP: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.BACKUP,
  GENERIC: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.GENERIC,
  ALLOCATED: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.ALLOCATED,
  STORAGE: 'storage',
  GET_STORAGE: 'getStorage',
  GET_USED_PERCENTAGE: 'getUsedPercentage',
  STATUS: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.STATUS,
  GET_STATUS: 'getStatus',
  GET_STATUS_PRIORITY: 'getStatusPriority',
  ALERTS_COUNT: CLUSTER_RESPONSE_KEYS.ROWS_FIELD.ALERTS_COUNT,
  ALERTS: 'alerts',
  GET_ALERTS: 'getAlerts',
  GET_ALERTS_FILTER: 'getAlertsFilter',
  GET_ALERTS_FILTER_LOCALE: 'getAlertsFilterLocale',
  ACTION: 'action',
  GET_ACTION: 'getAction'
} as const;

export enum CLUSTER_STATUS {
  ok = 'ok',
  warning = 'warning',
  critical = 'critical'
}

export const CLUSTERS_TABLE_COLUMN_ID = {
  [CLUSTERS_TABLE_KEYS.NAME]: CLUSTERS_TABLE_KEYS.NAME,
  [CLUSTERS_TABLE_KEYS.COMPANY_NAME]: CLUSTERS_TABLE_KEYS.COMPANY_NAME,
  [CLUSTERS_TABLE_KEYS.BUCKETS_COUNT]: CLUSTERS_TABLE_KEYS.BUCKETS_COUNT,
  [CLUSTERS_TABLE_KEYS.NODES_COUNT]: CLUSTERS_TABLE_KEYS.NODES_COUNT,
  [CLUSTERS_TABLE_KEYS.VERSION]: CLUSTERS_TABLE_KEYS.VERSION,
  [CLUSTERS_TABLE_KEYS.LAST_UPDATE]: CLUSTERS_TABLE_KEYS.GET_LAST_UPDATE,
  [CLUSTERS_TABLE_KEYS.STORAGE]: CLUSTERS_TABLE_KEYS.GET_STORAGE,
  [CLUSTERS_TABLE_KEYS.STATUS]: CLUSTERS_TABLE_KEYS.GET_STATUS,
  [CLUSTERS_TABLE_KEYS.ALERTS]: CLUSTERS_TABLE_KEYS.GET_ALERTS,
  [CLUSTERS_TABLE_KEYS.ACTION]: CLUSTERS_TABLE_KEYS.GET_ACTION
};

export const CLUSTERS_TABLE_SORT_ID = {
  [CLUSTERS_TABLE_KEYS.NAME]: CLUSTERS_TABLE_KEYS.NAME,
  [CLUSTERS_TABLE_KEYS.COMPANY_NAME]: CLUSTERS_TABLE_KEYS.COMPANY_NAME,
  [CLUSTERS_TABLE_KEYS.VERSION]: CLUSTERS_TABLE_KEYS.VERSION,
  [CLUSTERS_TABLE_KEYS.LAST_UPDATE]: CLUSTERS_TABLE_KEYS.LAST_UPDATE,
  [CLUSTERS_TABLE_KEYS.STORAGE]: CLUSTERS_TABLE_KEYS.GET_USED_PERCENTAGE,
  [CLUSTERS_TABLE_KEYS.STATUS]: CLUSTERS_TABLE_KEYS.GET_STATUS_PRIORITY
};

export const CLUSTERS_TABLE_COLUMN_WIDTH = {
  [CLUSTERS_TABLE_KEYS.NAME]: '10%',
  [CLUSTERS_TABLE_KEYS.COMPANY_NAME]: '10%',
  [CLUSTERS_TABLE_KEYS.BUCKETS_COUNT]: '10%',
  [CLUSTERS_TABLE_KEYS.NODES_COUNT]: '10%',
  [CLUSTERS_TABLE_KEYS.VERSION]: '10%',
  [CLUSTERS_TABLE_KEYS.LAST_UPDATE]: '10%',
  [CLUSTERS_TABLE_KEYS.STORAGE]: '10%',
  [CLUSTERS_TABLE_KEYS.STATUS]: '10%',
  [CLUSTERS_TABLE_KEYS.ALERTS]: '10%',
  [CLUSTERS_TABLE_KEYS.ACTION]: '80px'
};

export const CLUSTERS_TABLE_COLUMN_DISABLED_RESIZE = {
  [CLUSTERS_TABLE_KEYS.ACTION]: true
};
