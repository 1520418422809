import React, { FC, useState, useEffect, Suspense, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Login from 'containers/Login';
import Loader from 'components/Loader';
import Fallback from './Fallback';

import { LOADER_TYPE } from 'commonExtensions/constants';
import { onBrowserRefresh } from 'store/actions';
import {
  selectIsLogin,
  selectGetInitDataFetching,
  selectAuthorizationFetching
} from 'store/selectors';
import { AppDispatch, IAppIsLoginType, Pending } from 'types';

const AppPage = React.lazy(() => import('./AppPage'));

const App: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const authorizationFetching: Pending = useSelector(selectAuthorizationFetching);
  const isLoginStore: IAppIsLoginType = useSelector(selectIsLogin);
  const getInitDataFetching: Pending = useSelector(selectGetInitDataFetching);
  const [isLoginState, setIsLoginState] = useState<Pending>(false);
  const [initLoad, setInitLoad] = useState<boolean>(true);

  useEffect(() => {
    if (isLoginState !== isLoginStore) {
      setIsLoginState(isLoginStore);
      setInitLoad(false);
    }
  }, [isLoginStore, isLoginState]);

  const getAppInitialData = useCallback(() => {
    if (localStorage.getItem('stopIntervalRequests')) {
      localStorage.removeItem('stopIntervalRequests');
    }

    if (localStorage.getItem('preventPopupError')) {
      localStorage.removeItem('preventPopupError');
    }

    if (localStorage.getItem('isLogin') === 'true') {
      dispatch(onBrowserRefresh({ isLogin: false }));
    } else {
      setInitLoad(false);
    }
  }, [dispatch]);

  useEffect(() => {
    import('./AppPage');

    getAppInitialData();
  }, [dispatch, getAppInitialData]);

  return (
    <>
      <Suspense fallback={<Fallback />}>
        {isLoginState && !getInitDataFetching && !authorizationFetching && <AppPage />}
      </Suspense>
      {!initLoad && !isLoginState && !getInitDataFetching && <Login />}
      {(initLoad || (getInitDataFetching && !authorizationFetching)) && (
        <Loader type={LOADER_TYPE.authorization} />
      )}
    </>
  );
};

export default App;
