import { ALERTS_COUNT_RESPONSE_KEYS } from './alerts';

export const SERVICES_COUNT_RESPONSE_KEYS = {
  COUNT: 'count',
  ALERTS_COUNT: 'alertsCount',
  ALERTS_COUNT_FIELD: {
    CRITICAL: ALERTS_COUNT_RESPONSE_KEYS.CRITICAL,
    WARNING: ALERTS_COUNT_RESPONSE_KEYS.WARNING
  }
} as const;
