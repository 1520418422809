export enum TABLE_TYPE {
  CLUSTER = 'cluster'
}

export const TABLE_COLUMN_KEY = {
  ID: 'id',
  SORTING: 'sorting',
  NAME: 'name',
  DEFAULT_WIDTH: 'defaultWidth',
  RESIZE: 'resize'
} as const;

export enum TABLE_SORT_ORDER {
  desc = 'desc',
  asc = 'asc'
}

export enum IDS_TABLE_FILTER_FIRST_ACTION {
  filter = 'filter',
  search = 'search'
}

export const MIN_TABLE_ROWS_SHOW = 10;
