import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import {
  CLUSTERS_TABLE_COLUMN_ID,
  CLUSTERS_TABLE_SORT_ID,
  CLUSTERS_TABLE_COLUMN_WIDTH,
  CLUSTERS_TABLE_COLUMN_DISABLED_RESIZE
} from 'constants/clusters';
import { ALERT_SEVERITY } from 'constants/alerts';
import {
  TABLE_TYPE,
  TABLE_COLUMN_KEY,
  TABLE_SORT_ORDER,
  MIN_TABLE_ROWS_SHOW
} from 'constants/table';
import { ACCOUNT_TABLE_SETTINGS } from 'constants/account';
import { CLUSTERS_TABLE_KEYS, CLUSTER_STATUS } from 'constants/clusters';
import { clustersTableColumnName, alertSeverityLocale } from 'commonExtensions/locales';
import {
  ITableHeaderConfig,
  ITableHeaderConfigData,
  ITableColumnData,
  ITableConfigFn,
  ITableDefaultSortFn,
  ITableFilterChecked,
  ITableBody,
  ITableApplySearchFn,
  ITableSortDataFn,
  ITableApplyFilterFn,
  ITableColumnToFilterFn,
  ITableCalculateColWidthFn,
  ITableColumnIdValueFn,
  ITableSortIdValueFn,
  ITableColumnId,
  IClusterTableBody,
  ITableStatusIconProps,
  ITableGetFilterUniqItemByIdFn,
  ITableGetFilterUniqPopupDataFn,
  ITableGetFilterUniqValueFn,
  ITableGetTableIconClassNameFn,
  ISelectBoxOpt
} from 'types';

import locale from 'locale';

const getHeaderData = ({
  columnId,
  sortId,
  locale,
  defaultWidth,
  resize,
  settings
}: ITableHeaderConfigData): ITableColumnData[] => {
  return Object.keys(columnId).map((key: string) => ({
    [TABLE_COLUMN_KEY.ID]: key,
    [TABLE_COLUMN_KEY.SORTING]: !!sortId?.[key],
    [TABLE_COLUMN_KEY.NAME]: locale?.[key] || '',
    [TABLE_COLUMN_KEY.DEFAULT_WIDTH]:
      settings?.find((item) => item?.[ACCOUNT_TABLE_SETTINGS.COLUMNS_FIELD.ID] === key)?.[
        ACCOUNT_TABLE_SETTINGS.COLUMNS_FIELD.COLUMN_WIDTH
      ] ||
      defaultWidth?.[key] ||
      '10%',
    [TABLE_COLUMN_KEY.RESIZE]: !resize?.[key]
  }));
};

export const getHeaderConfig = ({ settings, type }: ITableHeaderConfig) => {
  if (type === TABLE_TYPE.CLUSTER)
    return getHeaderData({
      columnId: CLUSTERS_TABLE_COLUMN_ID,
      sortId: CLUSTERS_TABLE_SORT_ID,
      locale: clustersTableColumnName,
      defaultWidth: CLUSTERS_TABLE_COLUMN_WIDTH,
      resize: CLUSTERS_TABLE_COLUMN_DISABLED_RESIZE,
      settings
    });

  return [];
};

const getTableDefaultSort = ({ type }: ITableDefaultSortFn) => {
  if (type === TABLE_TYPE.CLUSTER)
    return { column: CLUSTERS_TABLE_KEYS.NAME, order: TABLE_SORT_ORDER.asc };

  return null;
};

export const getTableConfig = ({ type }: ITableConfigFn) => {
  return {
    defaultSort: getTableDefaultSort({ type }),
    filterOptions: {
      columns: getColumnsToFilter({ type })
    }
  };
};

export const calculateColumnWidths = ({ tableWidth, colsArr }: ITableCalculateColWidthFn) => {
  // Determine the fixed values in pixels (those with 'px')
  const fixedPxValues = colsArr
    .map((col) => (typeof col === 'string' && col.endsWith('px') ? parseInt(col) : 0))
    .filter((value) => value > 0);
  const fixedPxSum = fixedPxValues.reduce((sum, value) => sum + value, 0);
  // Calculate the available width for % and number values
  const availableWidth = tableWidth - fixedPxSum;

  // Select only percentage values
  const percentageValues = colsArr
    .map((col) => (typeof col === 'string' && col.endsWith('%') ? parseFloat(col) : 0))
    .filter((value) => value > 0);

  // Calculate the sum of percentage values
  const percentageSum = percentageValues.reduce((sum, value) => sum + value, 0);

  // Scale percentage values to 100% if the sum is not equal to 100
  const scaleFactor = percentageSum !== 100 ? 100 / percentageSum : 1;

  // Convert column values to pixels, accounting for scaling
  const convertedCols = colsArr.map((col) => {
    if (typeof col === 'number') return col; // Keep numbers unchanged
    if (typeof col === 'string') {
      if (col.endsWith('px')) return parseInt(col, 10); // Keep values with 'px' unchanged
      if (col.endsWith('%')) {
        const scaledPercentage = parseFloat(col) * scaleFactor; // Scale to 100%
        return (scaledPercentage / 100) * availableWidth; // Convert to pixels
      }
    }
    return 0;
  });

  const totalConverted = convertedCols.reduce((sum, col) => sum + col, 0);

  // Check if the sum equals `tableWidth`
  if (totalConverted === tableWidth) return convertedCols;

  // Identify the elements that can be adjusted (those that weren't % or 'px')
  const adjustableIndices = convertedCols
    .map((col, index) => (typeof colsArr[index] === 'number' ? index : null))
    .filter((index) => index !== null);

  // If the sum is less than `tableWidth`, increase the values
  if (totalConverted < tableWidth) {
    const difference = tableWidth - totalConverted;
    const increment = difference / adjustableIndices.length;
    const roundedIncrement = Math.floor(increment);
    const remainder = difference - roundedIncrement * adjustableIndices.length;

    // Distribute the difference across the values
    const result = convertedCols.map((col, index) => {
      if (adjustableIndices.includes(index)) {
        return col + roundedIncrement + (remainder > 0 ? 1 : 0);
      }
      return col;
    });

    // Adjust the remainder
    adjustableIndices.slice(0, remainder).forEach((index) => {
      result[index as number] += 1;
    });

    return result;
  }

  // If the sum is greater than `tableWidth`, decrease the values
  if (totalConverted > tableWidth) {
    const difference = totalConverted - tableWidth;
    const decrement = difference / adjustableIndices.length;
    const roundedDecrement = Math.floor(decrement);
    const remainder = difference - roundedDecrement * adjustableIndices.length;

    const result = convertedCols.map((col, index) => {
      if (adjustableIndices.includes(index)) {
        return col - roundedDecrement - (remainder > 0 ? 1 : 0);
      }
      return col;
    });

    adjustableIndices.slice(0, remainder).forEach((index) => {
      result[index as number] -= 1;
    });

    return result;
  }

  return convertedCols;
};

export const sortTableData = ({
  data,
  column,
  order,
  defaultSortColumn,
  tableType
}: ITableSortDataFn) => {
  let sortData = [];
  const columnSortIdValue = getColumnSortIdValue({ id: column, tableType });

  sortData = sortBy(
    data,
    (obj) => {
      if (typeof get(obj, columnSortIdValue) === 'number') {
        return get(obj, columnSortIdValue);
      } else if (typeof get(obj, columnSortIdValue) === 'string') {
        return get(obj, columnSortIdValue).toLowerCase();
      } else if (typeof get(obj, columnSortIdValue) === 'boolean') {
        return get(obj, columnSortIdValue).toString().toLowerCase();
      } else {
        return get(obj, columnSortIdValue);
      }
    },
    defaultSortColumn || columnSortIdValue
  );

  if (order === TABLE_SORT_ORDER.desc) {
    sortData = sortData.reverse();
  }

  return sortData;
};

export const selectedDefaultTableShowOpt = {
  value: MIN_TABLE_ROWS_SHOW,
  label: locale('IDS_TABLE_SHOW_SELECTBOX_TEN_ROWS_VALUE')
};

export const defaultTableShowOpts = [
  selectedDefaultTableShowOpt,
  { value: 25, label: locale('IDS_TABLE_SHOW_SELECTBOX_TWANTYFIVE_ROWS_VALUE') },
  { value: 50, label: locale('IDS_TABLE_SHOW_SELECTBOX_FIFTY_ROWS_VALUE') },
  { value: 100, label: locale('IDS_TABLE_SHOW_SELECTBOX_ONEHUNDRED_ROWS_VALUE') },
  {
    value: -1,
    label: locale('IDS_TABLE_SHOW_SELECTBOX_ALL_VALUE'),
    isAll: true
  }
];

export const checkFilterHasValues = (filterData: ITableFilterChecked | null) => {
  return !!(filterData && Object.values(filterData).flat().length > 0);
};

export const applyFilter = ({ selectedFilter, arr, tableType }: ITableApplyFilterFn) => {
  if (selectedFilter) {
    const activeFilters = Object.fromEntries(
      Object.entries(selectedFilter).filter(([, value]) => Array.isArray(value) && value.length > 0)
    );

    return arr.filter((item) => {
      return Object.entries(activeFilters).every(([key, values]) => {
        if (tableType === TABLE_TYPE.CLUSTER) {
          if (key === CLUSTERS_TABLE_KEYS.ALERTS) {
            const alertValues =
              item?.[CLUSTERS_TABLE_KEYS.GET_ALERTS_FILTER_LOCALE as keyof ITableBody];
            return (
              Array.isArray(alertValues) && values.every((val: string) => alertValues.includes(val))
            );
          }
        }

        return values.includes(
          item[getColumnIdValue({ id: key, tableType }) as keyof ITableBody] as string
        );
      });
    });
  }

  return arr;
};

export const applySearch = ({ tableData, searchValue, arr, tableType }: ITableApplySearchFn) => {
  if (
    (typeof searchValue === 'string' && searchValue.trim() === '') ||
    typeof searchValue !== 'string'
  )
    return arr;

  const lowerSearchValue = searchValue.toLowerCase();
  const searchedArr =
    arr?.filter((item) =>
      tableData?.header?.some((key) => {
        const value = item[getColumnIdValue({ id: key?.id, tableType }) as keyof ITableBody];
        return typeof value === 'string' && value.toLowerCase().includes(lowerSearchValue);
      })
    ) || [];

  return searchedArr;
};

export const getColumnsToFilter = ({ type }: ITableColumnToFilterFn) => {
  if (type === TABLE_TYPE.CLUSTER) return [CLUSTERS_TABLE_KEYS.STATUS, CLUSTERS_TABLE_KEYS.ALERTS];

  return [];
};

export const getColumnIdValue = ({ id, tableType }: ITableColumnIdValueFn): ITableColumnId => {
  if (tableType === TABLE_TYPE.CLUSTER)
    return CLUSTERS_TABLE_COLUMN_ID?.[id as keyof typeof CLUSTERS_TABLE_COLUMN_ID] || '';

  return '';
};

export const getColumnSortIdValue = ({ id, tableType }: ITableSortIdValueFn): ITableColumnId => {
  if (tableType === TABLE_TYPE.CLUSTER)
    return CLUSTERS_TABLE_SORT_ID?.[id as keyof typeof CLUSTERS_TABLE_SORT_ID] || '';

  return '';
};

export const getTableIconData = ({ data, columnKey, tableType }: ITableStatusIconProps) => {
  if (tableType === TABLE_TYPE.CLUSTER) {
    if (columnKey === CLUSTERS_TABLE_KEYS.STATUS) {
      return (data as IClusterTableBody)?.[columnKey as keyof IClusterTableBody] as CLUSTER_STATUS;
    }

    if (columnKey === CLUSTERS_TABLE_KEYS.ALERTS) {
      const columnIdValue = getColumnIdValue({ id: columnKey, tableType });
      return (data as IClusterTableBody)?.[
        columnIdValue as keyof IClusterTableBody
      ] as ALERT_SEVERITY;
    }
  }

  return '';
};

export const getTableIconClassName = ({
  data,
  columnKey,
  tableType
}: ITableGetTableIconClassNameFn) => {
  const iconEnum = getTableIconData({ data, columnKey, tableType });

  if (tableType === TABLE_TYPE.CLUSTER) {
    if (columnKey === CLUSTERS_TABLE_KEYS.STATUS) {
      if (iconEnum === CLUSTER_STATUS.ok) return 'ok';
      if (iconEnum === CLUSTER_STATUS.critical) return 'critical';
      if (iconEnum === CLUSTER_STATUS.warning) return 'warning';
    }

    if (columnKey === CLUSTERS_TABLE_KEYS.ALERTS) {
      if (iconEnum === ALERT_SEVERITY.ok) return 'ok';
      if (iconEnum === ALERT_SEVERITY.critical) return 'critical';
      if (iconEnum === ALERT_SEVERITY.warning) return 'warning';
    }
  }

  return '';
};

export const getFilterUniqItemById = ({
  bodyData,
  columnKey,
  tableType,
  columnIdValue
}: ITableGetFilterUniqItemByIdFn) => {
  if (tableType === TABLE_TYPE.CLUSTER) {
    if (columnKey === CLUSTERS_TABLE_KEYS.ALERTS) {
      const uniqAlertsObj: IClusterTableBody[] = [];
      const hasSeverity: ALERT_SEVERITY[] = [];

      Object.keys(ALERT_SEVERITY)?.forEach((severityKey) => {
        (bodyData as IClusterTableBody[])?.some((bodyItem) => {
          if (
            bodyItem?.[CLUSTERS_TABLE_KEYS.GET_ALERTS_FILTER]?.includes(
              severityKey as ALERT_SEVERITY
            ) &&
            !hasSeverity?.includes(severityKey as ALERT_SEVERITY)
          ) {
            hasSeverity.push(severityKey as ALERT_SEVERITY);
            uniqAlertsObj.push(bodyItem);
          }
        });
      });

      return uniqAlertsObj;
    }
  }

  return (
    uniqBy(bodyData, columnIdValue)?.filter((item) => item?.[columnIdValue as keyof ITableBody]) ||
    []
  );
};

export const getFilterUniqPopupData = ({
  uniqItemsById,
  columnKey,
  columnIdValue,
  tableType
}: ITableGetFilterUniqPopupDataFn): string[] => {
  if (tableType === TABLE_TYPE.CLUSTER) {
    if (columnKey === CLUSTERS_TABLE_KEYS.ALERTS) {
      const uniqSeverity = uniq(
        (uniqItemsById as IClusterTableBody[])?.flatMap(
          (item) => item?.[CLUSTERS_TABLE_KEYS.GET_ALERTS_FILTER]
        )
      );
      return uniqSeverity?.map(
        (item) => alertSeverityLocale?.[item as keyof typeof ALERT_SEVERITY]
      );
    }
  }

  return (
    (uniqItemsById as ITableBody[])?.map(
      (item: ITableBody) => item?.[columnIdValue as keyof ITableBody] as string
    ) || []
  );
};

export const getFilterUniqValue = ({
  bodyData,
  columnKey,
  tableType
}: ITableGetFilterUniqValueFn) => {
  const columnIdValue = getColumnIdValue({ id: columnKey, tableType });
  const uniqItemsById =
    sortBy(
      getFilterUniqItemById({ bodyData, columnIdValue, columnKey, tableType }),
      getColumnSortIdValue({ id: columnKey, tableType })
    ) || [];

  return {
    data: getFilterUniqPopupData({
      uniqItemsById,
      columnKey,
      columnIdValue,
      tableType
    }),
    extendData: uniqItemsById
  };
};

export const getTableColumnsData = ({
  widthArr,
  headerArr
}: {
  widthArr: number[];
  headerArr: ITableColumnData[];
}) => {
  return widthArr.map((width, index) => ({
    id: headerArr?.[index]?.[TABLE_COLUMN_KEY.ID],
    columnWidth: width
  }));
};

export const convertTableShowValueToSave = (selectedOpt: ISelectBoxOpt) => {
  if (selectedOpt?.isAll) return -1;

  return (selectedOpt?.value as number) || null;
};
