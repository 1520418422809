import * as api from 'api/api';
import { axiosPrivate } from 'api/axiosPrivate';
import { getCurrentTime } from 'commonExtensions/timeParser';
import {
  setOrganizations,
  setSelectedOrg,
  setGetSelectedOrganizationFetching,
  setSelectedOrgLastUpdate
} from 'store/features/organizations/orgSlice';
import { AppDispatch, IOrganizationGetRequestProps } from 'types';
import { ORG_FILTER_BTN_DATA } from 'constants/organizations';
import { ORG_RESPONSE_KEYS } from 'api/models/organizations';

export const getOrganizations = () => async (dispatch: AppDispatch) => {
  return await axiosPrivate
    .get(`${api.organizations}`)
    .then((response) => {
      dispatch(setOrganizations(response?.data || null));

      if (
        Array.isArray(response?.data?.[ORG_RESPONSE_KEYS.ROWS]) &&
        response?.data?.[ORG_RESPONSE_KEYS.ROWS]?.length === 1
      ) {
        dispatch(
          getSelectedOrganization({
            orgId: response.data[ORG_RESPONSE_KEYS.ROWS][0]?.[ORG_FILTER_BTN_DATA.ID]
          })
        );
      }

      return response?.data || null;
    })
    .catch((error) => {
      console.error('error', error);
    });
};

export const getSelectedOrganization =
  ({ orgId }: IOrganizationGetRequestProps) =>
  async (dispatch: AppDispatch) => {
    dispatch(setGetSelectedOrganizationFetching(true));

    await axiosPrivate
      .get(`${api.organizations}/${orgId}`)
      .then((response) => {
        dispatch(setSelectedOrg(response?.data || null));
        dispatch(setSelectedOrgLastUpdate(getCurrentTime()));
        dispatch(setGetSelectedOrganizationFetching(false));
      })
      .catch((error) => {
        console.error('error', error);
        dispatch(setSelectedOrgLastUpdate(getCurrentTime()));
        dispatch(setGetSelectedOrganizationFetching(false));
      });
  };
