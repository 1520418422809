import { createSelector } from '@reduxjs/toolkit';
import { COMPANY_FILTER_ID } from 'commonExtensions/constants';
import { preparedFilterBtnData } from 'components/CompanyFilter/common';
import { RootState, IOrganizationsResponseDataRows } from 'types';
import { selectAccountSettings } from 'store/selectors/account';
import { ORG_FILTER_BTN_DATA } from 'constants/organizations';
import { ORG_RESPONSE_KEYS } from 'api/models/organizations';
import { ACCOUNT_RESPONSE_KEYS } from 'api/models/account';
import { ACCOUNT_AVATAR_DATA } from 'constants/account';
import { getInitials } from 'commonExtensions/common';
import locale from 'locale';

export const selectOrganizations = (state: RootState) => state.organizations.organizations;
export const selectSelectedOrg = (state: RootState) => state.organizations.selectedOrg;
export const selectGetSelectedOrganizationFetching = (state: RootState) =>
  state.organizations.getSelectedOrganizationFetching;

export const selectOrgDetailsVisible = createSelector(
  [selectSelectedOrg, selectOrganizations],
  (selectedOrg, orgs) => {
    if (
      selectedOrg &&
      selectedOrg?.id !== COMPANY_FILTER_ID.all &&
      orgs?.[ORG_RESPONSE_KEYS.ROWS] &&
      orgs[ORG_RESPONSE_KEYS.ROWS].length > 1
    )
      return true;

    return false;
  }
);

export const selectOrgsFilterData = createSelector(
  [selectOrganizations, selectAccountSettings],
  (orgs, accSettings) => {
    return orgs?.[ORG_RESPONSE_KEYS.ROWS] && orgs[ORG_RESPONSE_KEYS.ROWS].length > 0
      ? preparedFilterBtnData(
          orgs?.[ORG_RESPONSE_KEYS.ROWS]?.map((item: IOrganizationsResponseDataRows) => ({
            ...item,
            [ORG_FILTER_BTN_DATA.PINNED]:
              accSettings?.[ACCOUNT_RESPONSE_KEYS.PERSONALIZATION]?.[
                ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.PINNED_ORG
              ]?.includes(item?.[ORG_RESPONSE_KEYS.ROWS_FIELD.ID]) || false
          })) || []
        )
      : [];
  }
);

export const selectOrgDetails = createSelector([selectSelectedOrg], (selectedOrg) => {
  const stats = [
    {
      title: locale('IDS_COMPANY_DETAILS_CLUSTERS_TITLE'),
      value: selectedOrg?.[ORG_RESPONSE_KEYS.ROWS_FIELD.CLUSTER_COUNT] || 0
    },
    {
      title: locale('IDS_COMPANY_DETAILS_NODES_TITLE'),
      value: selectedOrg?.[ORG_RESPONSE_KEYS.ROWS_FIELD.NODES_COUNT] || 0
    },
    {
      title: locale('IDS_COMPANY_DETAILS_BUCKETS_TITLE'),
      value: selectedOrg?.[ORG_RESPONSE_KEYS.ROWS_FIELD.BUCKETS_COUNT] || 0
    },
    {
      title: locale('IDS_COMPANY_DETAILS_NETWORKS_TITLE'),
      value: selectedOrg?.[ORG_RESPONSE_KEYS.ROWS_FIELD.NETWORKS_COUNT] || 0
    }
  ];

  return {
    id: selectedOrg?.[ORG_RESPONSE_KEYS.ROWS_FIELD.ID] || '',
    name: selectedOrg?.[ORG_RESPONSE_KEYS.ROWS_FIELD.NAME] || '',
    stats,
    avatars:
      selectedOrg?.[ORG_RESPONSE_KEYS.ROWS_FIELD.USERS]?.map((item) => ({
        [ACCOUNT_AVATAR_DATA.ID]: item?.[ORG_RESPONSE_KEYS.ROWS_FIELD.USERS_FIELD.ID] || '',
        [ACCOUNT_AVATAR_DATA.NAME]: item?.[ORG_RESPONSE_KEYS.ROWS_FIELD.USERS_FIELD.NAME] || '',
        [ACCOUNT_AVATAR_DATA.INITIALS]: getInitials(
          item?.[ORG_RESPONSE_KEYS.ROWS_FIELD.USERS_FIELD.NAME] || ''
        ),
        [ACCOUNT_AVATAR_DATA.EMAIL]: item?.[ORG_RESPONSE_KEYS.ROWS_FIELD.USERS_FIELD.EMAIL] || '',
        [ACCOUNT_AVATAR_DATA.AVATAR_COLOR]:
          item?.[ORG_RESPONSE_KEYS.ROWS_FIELD.USERS_FIELD.AVATAR_COLOR] || ''
      })) || []
  };
});
