export const ALERTS_COUNT_RESPONSE_KEYS = {
  CRITICAL: 'critical',
  WARNING: 'warning'
} as const;

export const ALERTS_RESPONSE_KEYS = {
  ALERTS_COUNT: 'alertsCount',
  ALERTS_COUNT_FIELD: ALERTS_COUNT_RESPONSE_KEYS,
  ROWS: 'rows',
  ROWS_FIELD: {
    ID: 'id',
    SEVERITY: 'severity',
    TIME: 'time',
    SOURCE: 'source',
    OBJECT: 'object',
    COMPANY_NAME: 'companyName',
    DESCRIPTION: 'description',
    TICKET: 'ticket',
    STATUS: 'status'
  }
} as const;
