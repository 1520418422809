import * as api from 'api/api';
import { axiosPrivate } from 'api/axiosPrivate';
import { setAccountSettings } from 'store/features/account/accountSlice';
import { AppDispatch, IAccountSettingsPutRequestProps } from 'types';

export const getAccountSettings = () => async (dispatch: AppDispatch) => {
  await axiosPrivate
    .get(`${api.account}/settings`)
    .then((response) => {
      dispatch(setAccountSettings(response?.data || null));
    })
    .catch((error) => {
      console.error('error', error);
    });
};

export const putAccountSettings =
  ({ data }: IAccountSettingsPutRequestProps) =>
  (dispatch: AppDispatch) => {
    axiosPrivate
      .put(`${api.account}/settings`, data)
      .then(() => {
        dispatch(getAccountSettings());
      })
      .catch((error) => {
        console.error('error', error);
      });
  };
