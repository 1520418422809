let url = '';

if (process.env.NODE_ENV === 'development') {
  url = process.env.REACT_APP_PUBLIC_URL || 'https://192.168.12.222:8443';
} else {
  url = '';
}

export { url };
export const config = '/api/v1';
export const account = '/api/v1/account';
export const organizations = '/api/v1/organizations';
export const clusters = '/api/v1/clusters';
export const renewToken = '/api/v1/account/renewToken';
export const authorization = '/api/v1/account/login';
export const alerts = '/api/v1/alerts';
