import { ALERTS_COUNT_RESPONSE_KEYS } from './alerts';

export const DISKS_COUNT_RESPONSE_KEYS = {
  COUNT: 'count',
  ALERTS_COUNT: 'alertsCount',
  ALERTS_COUNT_FIELD: {
    CRITICAL: ALERTS_COUNT_RESPONSE_KEYS.CRITICAL,
    WARNING: ALERTS_COUNT_RESPONSE_KEYS.WARNING
  }
} as const;

export const DISK_RESPONSE_KEYS = {
  ROWS: 'rows',
  ROWS_FIELD: {
    ID: 'id',
    NODE_NAME: 'nodeName',
    STATUS: 'status',
    DISKS: 'disks',
    DISKS_FIELD: {
      ID: 'id',
      NAME: 'name',
      VERSIONING: 'versioning',
      OWNER: 'owner',
      LOCATION: 'location',
      BACKUP: 'backup',
      GENERIC: 'generic',
      CAPACITY: 'capacity',
      ALERTS_COUNT: 'alertsCount',
      ALERTS_COUNT_FIELD: {
        CRITICAL: ALERTS_COUNT_RESPONSE_KEYS.CRITICAL,
        WARNING: ALERTS_COUNT_RESPONSE_KEYS.WARNING
      }
    }
  }
} as const;
