import { ALERTS_RESPONSE_KEYS } from 'api/models/alerts';

export const ALERTS_TABLE_KEYS = {
  ID: ALERTS_RESPONSE_KEYS.ROWS_FIELD.ID,
  SEVERITY: ALERTS_RESPONSE_KEYS.ROWS_FIELD.SEVERITY,
  GET_SEVERITY: 'getSeverity',
  TIME: ALERTS_RESPONSE_KEYS.ROWS_FIELD.TIME,
  GET_TIME: 'getTime',
  SOURCE: ALERTS_RESPONSE_KEYS.ROWS_FIELD.SOURCE,
  GET_SOURCE: 'getSource',
  OBJECT: ALERTS_RESPONSE_KEYS.ROWS_FIELD.OBJECT,
  COMPANY_NAME: ALERTS_RESPONSE_KEYS.ROWS_FIELD.COMPANY_NAME,
  DESCRIPTION: ALERTS_RESPONSE_KEYS.ROWS_FIELD.DESCRIPTION,
  TICKET: ALERTS_RESPONSE_KEYS.ROWS_FIELD.TICKET,
  GET_TICKET: 'getTicket',
  STATUS: ALERTS_RESPONSE_KEYS.ROWS_FIELD.STATUS,
  GET_STATUS: 'getStatus',
  GET_STATUS_PRIORITY: 'getStatusPriority'
} as const;

export const ALERTS_WIDGET_KEYS = {
  ALERTS_COUNT: ALERTS_RESPONSE_KEYS.ALERTS_COUNT,
  ALERTS_COUNT_FIELD: ALERTS_RESPONSE_KEYS.ALERTS_COUNT_FIELD,
  ROWS: ALERTS_RESPONSE_KEYS.ROWS,
  ROWS_FIELD: ALERTS_TABLE_KEYS
};

export enum ALERT_SEVERITY {
  critical = 'critical',
  warning = 'warning',
  ok = 'ok'
}

export enum ALERTS_STATUS {
  solved = 'solved',
  inProgress = 'in_progress'
}

export enum ALERTS_SOURCE {
  bucket = 'bucket',
  cluster = 'cluster',
  node = 'node',
  disk = 'disk',
  network = 'network'
}

export enum ALERTS_FILTER_ID {
  all = 'all'
}
