import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import {
  RootState,
  IAlertsResponseData,
  IOrganizationsName,
  IAlertTicket,
  IAlertDescription,
  IAlertObject,
  IAlertTime,
  IAlertId,
  IAlertsCount,
  IAlertsWidgetAlertsFilterId
} from 'types';
import { ALERTS_RESPONSE_KEYS } from 'api/models/alerts';
import {
  ALERTS_TABLE_KEYS,
  ALERTS_WIDGET_KEYS,
  ALERT_SEVERITY,
  ALERTS_SOURCE,
  ALERTS_STATUS
} from 'constants/alerts';
import { timeParserUtcToDate } from 'commonExtensions/timeParser';
import {
  alertSeverityLocale,
  alertSourceLocale,
  alertStatusLocale
} from 'commonExtensions/locales';

export const selectAlerts = (state: RootState) => state.alerts.alerts;
export const selectAlertsWidgetSelectedFilter = (state: RootState) =>
  state.alerts.widgetSelectedFilter;

class AlertsTable {
  [ALERTS_TABLE_KEYS.ID]!: IAlertId;
  [ALERTS_TABLE_KEYS.SEVERITY]!: ALERT_SEVERITY | string;
  [ALERTS_TABLE_KEYS.TIME]!: IAlertTime;
  [ALERTS_TABLE_KEYS.SOURCE]!: IAlertsWidgetAlertsFilterId;
  [ALERTS_TABLE_KEYS.OBJECT]!: IAlertObject;
  [ALERTS_TABLE_KEYS.COMPANY_NAME]!: IOrganizationsName;
  [ALERTS_TABLE_KEYS.DESCRIPTION]!: IAlertDescription;
  [ALERTS_TABLE_KEYS.TICKET]!: IAlertTicket;
  [ALERTS_TABLE_KEYS.STATUS]!: ALERTS_STATUS | string;

  constructor(data: IAlertsResponseData) {
    this[ALERTS_TABLE_KEYS.ID] = data[ALERTS_RESPONSE_KEYS.ROWS_FIELD.ID];
    this[ALERTS_TABLE_KEYS.SEVERITY] = data[ALERTS_RESPONSE_KEYS.ROWS_FIELD.SEVERITY];
    this[ALERTS_TABLE_KEYS.TIME] = data[ALERTS_RESPONSE_KEYS.ROWS_FIELD.TIME];
    this[ALERTS_TABLE_KEYS.SOURCE] = data[ALERTS_RESPONSE_KEYS.ROWS_FIELD.SOURCE];
    this[ALERTS_TABLE_KEYS.OBJECT] = data[ALERTS_RESPONSE_KEYS.ROWS_FIELD.OBJECT];
    this[ALERTS_TABLE_KEYS.COMPANY_NAME] = data[ALERTS_RESPONSE_KEYS.ROWS_FIELD.COMPANY_NAME];
    this[ALERTS_TABLE_KEYS.DESCRIPTION] = data[ALERTS_RESPONSE_KEYS.ROWS_FIELD.DESCRIPTION];
    this[ALERTS_TABLE_KEYS.TICKET] = data[ALERTS_RESPONSE_KEYS.ROWS_FIELD.TICKET];
    this[ALERTS_TABLE_KEYS.STATUS] = data[ALERTS_RESPONSE_KEYS.ROWS_FIELD.STATUS];
  }

  get [ALERTS_TABLE_KEYS.GET_SEVERITY]() {
    return alertSeverityLocale[this[ALERTS_TABLE_KEYS.SEVERITY] as ALERT_SEVERITY] || '';
  }

  get [ALERTS_TABLE_KEYS.GET_TIME]() {
    return timeParserUtcToDate({ utcString: this[ALERTS_TABLE_KEYS.TIME] });
  }

  get [ALERTS_TABLE_KEYS.GET_SOURCE]() {
    return alertSourceLocale[this[ALERTS_TABLE_KEYS.SOURCE] as ALERTS_SOURCE] || '';
  }

  get [ALERTS_TABLE_KEYS.GET_TICKET]() {
    return `#${this[ALERTS_TABLE_KEYS.TICKET]}`;
  }

  get [ALERTS_TABLE_KEYS.GET_STATUS]() {
    return alertStatusLocale[this[ALERTS_TABLE_KEYS.STATUS] as ALERTS_STATUS] || '';
  }

  get [ALERTS_TABLE_KEYS.GET_STATUS_PRIORITY]() {
    if (this[ALERTS_TABLE_KEYS.STATUS] === ALERT_SEVERITY.critical) return 0;
    if (this[ALERTS_TABLE_KEYS.STATUS] === ALERT_SEVERITY.warning) return 1;
    if (this[ALERTS_TABLE_KEYS.STATUS] === ALERT_SEVERITY.ok) return 2;

    return 0;
  }
}

export const selectAlertsWidgeCounter = createSelector([selectAlerts], (alerts) => {
  const arr = [];
  const alertsCount = alerts?.[ALERTS_WIDGET_KEYS.ALERTS_COUNT] as IAlertsCount;

  if (alertsCount) {
    for (const key in alertsCount) {
      if (alertsCount[key as keyof IAlertsCount]) {
        arr.push({
          id: key,
          value: alertsCount[key as keyof IAlertsCount]
        });
      }
    }
  }

  return arr;
});

export const selectAlertsWidgetFilter = createSelector([selectAlerts], (alerts) => {
  const groupedAlerts =
    groupBy(alerts?.[ALERTS_RESPONSE_KEYS.ROWS], ALERTS_RESPONSE_KEYS.ROWS_FIELD.SOURCE) || {};

  return (
    Object.keys(groupedAlerts).map((key) => ({
      id: key,
      title: alertSourceLocale[key as ALERTS_SOURCE] || ''
    })) || []
  );
});

export const selectAlertsWidgetList = createSelector([selectAlerts], (alerts) => {
  return alerts?.[ALERTS_RESPONSE_KEYS.ROWS]?.map((item) => new AlertsTable(item)) || [];
});
