import moment from 'moment-timezone';
import { DATE_FORMAT, TIME_FORMAT } from 'commonExtensions/constants';
import { ITimeParserUtcToUnix, ITimeUnix } from 'types';

export const getDateFormat = () => {
  // return JSON.parse(localStorage.getItem('date_format') || '');
  return {
    date: DATE_FORMAT.USA,
    time: TIME_FORMAT.TWENTY_FOUR
  };
};

export const onlyDateFormatCheck = (): string => {
  const format = getDateFormat();

  if (format?.date === DATE_FORMAT.EUROPE) return 'DD/MM/YY';
  if (format?.date === DATE_FORMAT.USA) return 'MM/DD/YY';
  if (format?.date === DATE_FORMAT.JAPAN) return 'YY/MM/DD';

  return 'DD/MM/YY';
};

export const getTimeFormat = (sec?: boolean) => {
  const format = getDateFormat();

  return format?.time === TIME_FORMAT.TWENTY_FOUR
    ? `HH:mm${sec ? ':ss' : ''}`
    : `hh:mm${sec ? ':ss' : ''} A`;
};

const dateFormatCheck = ({ timeFirst }: { timeFirst?: boolean }): string => {
  const dayFormat = onlyDateFormatCheck();
  const timeFormat = getTimeFormat(true);

  return timeFirst ? `${timeFormat}, ${dayFormat}` : `${dayFormat}, ${timeFormat}`;
};

const getTimeZone = () => {
  const timeZoneValue = localStorage.getItem('timeZone');

  return timeZoneValue && timeZoneValue !== 'null' ? timeZoneValue : '';
};

export const timeParserUtcToUnix = ({ utc }: ITimeParserUtcToUnix): ITimeUnix => {
  if (utc && typeof utc === 'string') {
    return moment.tz(utc, getTimeZone()).unix();
  }

  return NaN;
};

export const timeParserCurrentUtcUnix = (): ITimeUnix => {
  const time: ITimeUnix = timeParserUtcToUnix({
    utc: moment.tz(moment().toISOString(), getTimeZone()).format()
  });

  return time ? time : NaN;
};

export const getCurrentTime = (): string => {
  return moment.tz(moment(), getTimeZone()).format(dateFormatCheck({ timeFirst: false }));
};

export const timeParserUtcToDate = ({
  utcString,
  timeFirst
}: {
  utcString: string;
  timeFirst?: boolean;
}) => {
  if (utcString && typeof utcString === 'string') {
    return moment.tz(utcString, getTimeZone()).format(dateFormatCheck({ timeFirst: timeFirst }));
  }

  return '';
};
