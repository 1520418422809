import { ALERTS_COUNT_RESPONSE_KEYS } from './alerts';
import { SERVICES_COUNT_RESPONSE_KEYS } from './services';
import { DISKS_COUNT_RESPONSE_KEYS } from './disks';
import { NETWORKS_COUNT_RESPONSE_KEYS } from './networks';

export const CLUSTER_RESPONSE_KEYS = {
  ROWS: 'rows',
  ROWS_FIELD: {
    ID: 'id',
    NAME: 'name',
    COMPANY_NAME: 'companyName',
    COMPANY_ID: 'companyId',
    STATUS: 'status',
    VERSION: 'version',
    LOCATION: 'location',
    CAPACITY: 'capacity',
    BACKUP: 'backup',
    GENERIC: 'generic',
    ALLOCATED: 'allocated',
    LAST_UPDATE: 'lastUpdate',
    ALERTS_COUNT: 'alertsCount',
    ALERTS_COUNT_FIELD: {
      CRITICAL: ALERTS_COUNT_RESPONSE_KEYS.CRITICAL,
      WARNING: ALERTS_COUNT_RESPONSE_KEYS.WARNING
    },
    BUCKETS_COUNT: 'bucketsCount',
    NODES_COUNT: 'nodesCount',
    SERVICES: 'services',
    SERVICES_FIELD: {
      COUNT: SERVICES_COUNT_RESPONSE_KEYS.COUNT,
      ALERTS_COUNT: SERVICES_COUNT_RESPONSE_KEYS.ALERTS_COUNT,
      ALERTS_COUNT_FIELD: {
        CRITICAL: SERVICES_COUNT_RESPONSE_KEYS.ALERTS_COUNT_FIELD.CRITICAL,
        WARNING: SERVICES_COUNT_RESPONSE_KEYS.ALERTS_COUNT_FIELD.WARNING
      }
    },
    NETWORKS: 'networks',
    NETWORKS_FIELD: {
      COUNT: NETWORKS_COUNT_RESPONSE_KEYS.COUNT,
      ALERTS_COUNT: NETWORKS_COUNT_RESPONSE_KEYS.ALERTS_COUNT,
      ALERTS_COUNT_FIELD: {
        CRITICAL: NETWORKS_COUNT_RESPONSE_KEYS.ALERTS_COUNT_FIELD.CRITICAL,
        WARNING: NETWORKS_COUNT_RESPONSE_KEYS.ALERTS_COUNT_FIELD.WARNING
      }
    },
    DISKS: 'disks',
    DISKS_FIELD: {
      COUNT: DISKS_COUNT_RESPONSE_KEYS.COUNT,
      ALERTS_COUNT: DISKS_COUNT_RESPONSE_KEYS.ALERTS_COUNT,
      ALERTS_COUNT_FIELD: {
        CRITICAL: DISKS_COUNT_RESPONSE_KEYS.ALERTS_COUNT_FIELD.CRITICAL,
        WARNING: DISKS_COUNT_RESPONSE_KEYS.ALERTS_COUNT_FIELD.WARNING
      }
    }
  }
} as const;
