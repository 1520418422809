import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { ACCOUNT_RESPONSE_KEYS } from 'api/models/account';
import { ACCOUNT_AVATAR_DATA } from 'constants/account';
import { getInitials } from 'commonExtensions/common';

export const selectAccountSettings = (state: RootState) => state.account.settings;

export const selectCurrentUserAvatarData = createSelector([selectAccountSettings], (value) => {
  return {
    [ACCOUNT_AVATAR_DATA.ID]: value?.[ACCOUNT_AVATAR_DATA.ID] || '',
    [ACCOUNT_AVATAR_DATA.NAME]: value?.[ACCOUNT_AVATAR_DATA.NAME] || '',
    [ACCOUNT_AVATAR_DATA.INITIALS]: getInitials(value?.[ACCOUNT_AVATAR_DATA.NAME] || ''),
    [ACCOUNT_AVATAR_DATA.EMAIL]: value?.[ACCOUNT_AVATAR_DATA.EMAIL] || '',
    [ACCOUNT_AVATAR_DATA.AVATAR_COLOR]:
      value?.[ACCOUNT_RESPONSE_KEYS.PERSONALIZATION]?.[
        ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.AVATAR_COLOR
      ] || ''
  };
});

export const selectAccountPinnedOrgs = createSelector([selectAccountSettings], (value) => {
  return (
    value?.[ACCOUNT_RESPONSE_KEYS.PERSONALIZATION]?.[
      ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.PINNED_ORG
    ] || []
  );
});

export const selectAccountTableSettings = createSelector(
  [selectAccountSettings],
  (accountSettings) => {
    return (
      accountSettings?.[ACCOUNT_RESPONSE_KEYS.PERSONALIZATION]?.[
        ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.TABLE
      ] || []
    );
  }
);
