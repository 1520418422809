import { createSelector } from '@reduxjs/toolkit';
import { CLUSTERS_TABLE_KEYS, CLUSTER_STATUS } from 'constants/clusters';
import { CLUSTER_RESPONSE_KEYS } from 'api/models/clusters';
import { ACCOUNT_RESPONSE_KEYS } from 'api/models/account';
import { ACCOUNT_TABLE_SETTINGS } from 'constants/account';
import { ALERT_SEVERITY } from 'constants/alerts';
import { timeParserUtcToDate } from 'commonExtensions/timeParser';
import { clusterStatusLocale } from 'commonExtensions/locales';
import { getAlertsCountArr } from 'commonExtensions/common';
import { convertBytes } from 'commonExtensions/bytesParser';
import { selectAccountSettings } from 'store/selectors/account';
import { TABLE_TYPE } from 'constants/table';
import { getHeaderConfig, getTableConfig } from 'components/Table/extension';
import { alertSeverityLocale } from 'commonExtensions/locales';
import {
  IClusterId,
  IClusterName,
  IOrganizationsName,
  IOrganizationsId,
  IClusterBucketsCount,
  IClusterNodesCount,
  IClusterVersion,
  IClusterLastUpdate,
  IClusterCapacity,
  IClusterBackup,
  IClusterGeneric,
  IClusterAllocated,
  IClusterStatus,
  IAlertsCount,
  IClustersResponseDataRows,
  RootState,
  ITableBody
} from 'types';
import locale from 'locale';

class ClustersTable {
  [CLUSTERS_TABLE_KEYS.ID]!: IClusterId;
  [CLUSTERS_TABLE_KEYS.NAME]!: IClusterName;
  [CLUSTERS_TABLE_KEYS.COMPANY_NAME]!: IOrganizationsName;
  [CLUSTERS_TABLE_KEYS.COMPANY_ID]!: IOrganizationsId;
  [CLUSTERS_TABLE_KEYS.BUCKETS_COUNT]!: IClusterBucketsCount;
  [CLUSTERS_TABLE_KEYS.NODES_COUNT]!: IClusterNodesCount;
  [CLUSTERS_TABLE_KEYS.VERSION]!: IClusterVersion;
  [CLUSTERS_TABLE_KEYS.LAST_UPDATE]!: IClusterLastUpdate;
  [CLUSTERS_TABLE_KEYS.CAPACITY]!: IClusterCapacity;
  [CLUSTERS_TABLE_KEYS.BACKUP]!: IClusterBackup;
  [CLUSTERS_TABLE_KEYS.GENERIC]!: IClusterGeneric;
  [CLUSTERS_TABLE_KEYS.ALLOCATED]!: IClusterAllocated;
  [CLUSTERS_TABLE_KEYS.STATUS]!: IClusterStatus;
  [CLUSTERS_TABLE_KEYS.ALERTS_COUNT]!: IAlertsCount;

  constructor(data: IClustersResponseDataRows) {
    this[CLUSTERS_TABLE_KEYS.ID] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.ID];
    this[CLUSTERS_TABLE_KEYS.NAME] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.NAME];
    this[CLUSTERS_TABLE_KEYS.COMPANY_NAME] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.COMPANY_NAME];
    this[CLUSTERS_TABLE_KEYS.COMPANY_ID] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.COMPANY_ID];
    this[CLUSTERS_TABLE_KEYS.BUCKETS_COUNT] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.BUCKETS_COUNT];
    this[CLUSTERS_TABLE_KEYS.NODES_COUNT] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.NODES_COUNT];
    this[CLUSTERS_TABLE_KEYS.VERSION] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.VERSION];
    this[CLUSTERS_TABLE_KEYS.LAST_UPDATE] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.LAST_UPDATE];
    this[CLUSTERS_TABLE_KEYS.CAPACITY] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.CAPACITY];
    this[CLUSTERS_TABLE_KEYS.BACKUP] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.BACKUP];
    this[CLUSTERS_TABLE_KEYS.GENERIC] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.GENERIC];
    this[CLUSTERS_TABLE_KEYS.ALLOCATED] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.ALLOCATED];
    this[CLUSTERS_TABLE_KEYS.STATUS] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.STATUS];
    this[CLUSTERS_TABLE_KEYS.ALERTS_COUNT] = data[CLUSTER_RESPONSE_KEYS.ROWS_FIELD.ALERTS_COUNT];
  }

  get [CLUSTERS_TABLE_KEYS.GET_LAST_UPDATE]() {
    return timeParserUtcToDate({ utcString: this[CLUSTERS_TABLE_KEYS.LAST_UPDATE] });
  }

  get [CLUSTERS_TABLE_KEYS.GET_STATUS]() {
    return clusterStatusLocale[this[CLUSTERS_TABLE_KEYS.STATUS] as CLUSTER_STATUS] || '';
  }

  get [CLUSTERS_TABLE_KEYS.GET_STATUS_PRIORITY]() {
    if (this[CLUSTERS_TABLE_KEYS.STATUS] === CLUSTER_STATUS.critical) return 0;
    if (this[CLUSTERS_TABLE_KEYS.STATUS] === CLUSTER_STATUS.warning) return 1;
    if (this[CLUSTERS_TABLE_KEYS.STATUS] === CLUSTER_STATUS.ok) return 2;

    return 0;
  }

  get [CLUSTERS_TABLE_KEYS.GET_ALERTS]() {
    return getAlertsCountArr(this[CLUSTERS_TABLE_KEYS.ALERTS_COUNT]);
  }

  get [CLUSTERS_TABLE_KEYS.GET_ALERTS_FILTER]() {
    return getAlertsCountArr(this[CLUSTERS_TABLE_KEYS.ALERTS_COUNT])?.map((item) => item?.id);
  }

  get [CLUSTERS_TABLE_KEYS.GET_ALERTS_FILTER_LOCALE]() {
    return getAlertsCountArr(this[CLUSTERS_TABLE_KEYS.ALERTS_COUNT])?.map(
      (item) => alertSeverityLocale?.[item?.id as keyof typeof ALERT_SEVERITY]
    );
  }

  getUsedTotal() {
    return (
      this[CLUSTERS_TABLE_KEYS.BACKUP] +
      this[CLUSTERS_TABLE_KEYS.GENERIC] +
      this[CLUSTERS_TABLE_KEYS.ALLOCATED]
    );
  }

  get [CLUSTERS_TABLE_KEYS.GET_USED_PERCENTAGE]() {
    return +((this.getUsedTotal() * 100) / this[CLUSTERS_TABLE_KEYS.CAPACITY]).toFixed(2);
  }

  get [CLUSTERS_TABLE_KEYS.GET_STORAGE]() {
    const usedPrecentage = this[CLUSTERS_TABLE_KEYS.GET_USED_PERCENTAGE];

    return {
      str: `${convertBytes(this.getUsedTotal())}/${convertBytes(this[CLUSTERS_TABLE_KEYS.CAPACITY])} ${usedPrecentage}% ${locale('IDS_CLUSTERS_STORAGE_USED')}`,
      usedPrecentage
    };
  }

  get [CLUSTERS_TABLE_KEYS.GET_ACTION]() {
    return {
      navigate: this[CLUSTERS_TABLE_KEYS.ID]
    };
  }
}

export const selectClusters = (state: RootState) => state.clusters.clusters;
export const selectGetClustersFetching = (state: RootState) => state.clusters.getClustersFetching;
export const selectClustersLastUpdate = (state: RootState) => state.clusters.clustersLastUpdate;

export const selectClustersTableData = createSelector(
  [selectClusters, selectAccountSettings, selectClustersLastUpdate],
  (clusters, accountSettings, lastUpdate) => {
    return {
      header: getHeaderConfig({
        type: TABLE_TYPE.CLUSTER,
        settings:
          accountSettings?.[ACCOUNT_RESPONSE_KEYS.PERSONALIZATION]?.[
            ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.TABLE
          ]?.find((item) => item?.[ACCOUNT_TABLE_SETTINGS.ID] === TABLE_TYPE.CLUSTER)?.[
            ACCOUNT_TABLE_SETTINGS.COLUMNS
          ] || []
      }),
      body:
        clusters?.[CLUSTER_RESPONSE_KEYS.ROWS]?.map(
          (item) => new ClustersTable(item) as ITableBody
        ) || [],
      config: getTableConfig({ type: TABLE_TYPE.CLUSTER }),
      lastUpdate,
      type: TABLE_TYPE.CLUSTER
    };
  }
);
