const strings: { [key: string]: string } = {
  IDS_COMMON_BTN_CANCEL_CAPTION: 'Cancel',
  IDS_COMMON_BTN_CONFIRM_CAPTION: 'Confirm',
  IDS_COMMON_NO_RESULTS: 'No results',
  IDS_COMMON_OF: 'of',
  IDS_LOGIN_BTN_CAPTION: 'Login',
  IDS_LOGIN_INPUT_LOGIN_LABEL: 'Login',
  IDS_LOGIN_INPUT_PASS_LABEL: 'Password',
  IDS_LOGIN_INVALIDCREDS_ERROR_MSG: 'Invalid credentials. Please check the login and password.',
  IDS_LOGIN_INTERNALSERVERERROR_ERROR_MSG: 'An internal server error occurred. If the issue persists, please contact support.',
  IDS_LOGIN_UNNABLETOCONNECTTOBACKEND_ERROR_MSG: 'Unable to connect to backend. Please, try to refresh the page.',
  IDS_NAVIGATION_TAB_DASHBOARD_TITLE: 'Dashboard',
  IDS_NAVIGATION_TAB_ALERTS_TITLE: 'Alerts',
  IDS_NAVIGATION_TAB_EVENTS_TITLE: 'Events',
  IDS_HEADER_SIZER_LINK: 'https://dev.calculator.objectfirst.com/',
  IDS_HEADER_SIZER_TITLE: 'Sizer calculator',
  IDS_HEADER_SIZER_SUBTITLE: 'Try now!',
  IDS_HEADER_LOGOUT_BTN_CAPTION: 'Log out',
  IDS_HEADER_LOGOUT_CONFIRM_MODAL_TITLE: 'Log out',
  IDS_HEADER_LOGOUT_CONFIRM_MODAL_MSG: 'Are you sure you would like to log out',
  IDS_HEADER_LOGOUT_CONFIRM_MODAL_CONFIRM_BTN_CAPTION: 'Yes, log out',
  IDS_COMPANY_FILTER_ALL_ORGANIZATIONS_LABEL: 'All Organizations',
  IDS_PIN_BTN_TOOLTIP_PIN: 'Pin',
  IDS_PIN_BTN_TOOLTIP_UNPIN: 'Unpin',
  IDS_PIN_BTN_TOOLTIP_CANNOTPINMORE: 'Max',
  IDS_PIN_BTN_TOOLTIP_CANNOTPINMORE_ORGANIZATIONS: 'orgs. to pin',
  IDS_COMPANY_DETAILS_USERS_LABEL: 'Users',
  IDS_COMPANY_DETAILS_USERS_POPUP_TITLE: 'Participants',
  IDS_COMPANY_DETAILS_CLUSTERS_TITLE: 'Clusters',
  IDS_COMPANY_DETAILS_NODES_TITLE: 'Nodes',
  IDS_COMPANY_DETAILS_BUCKETS_TITLE: 'Buckets',
  IDS_COMPANY_DETAILS_NETWORKS_TITLE: 'Networks',
  IDS_ALERTS_SEVERITY_CRITICAL: 'Critical',
  IDS_ALERTS_SEVERITY_WARNING: 'Warning',
  IDS_ALERTS_SEVERITY_OK: 'All good',
  IDS_ALERTS_SOURCE_BUCKET: 'Bucket',
  IDS_ALERTS_SOURCE_CLUSTER: 'Cluster',
  IDS_ALERTS_SOURCE_DISK: 'Disk',
  IDS_ALERTS_SOURCE_NETWORK: 'Network',
  IDS_ALERTS_SOURCE_NODE: 'Node',
  IDS_ALERTS_STATUS_SOLVED: 'Solved',
  IDS_ALERTS_STATUS_IN_PROGRESS: 'In progress',
  IDS_ALERTS_WIDGET_TITLE: 'Alerts',
  IDS_ALERTS_WIDGET_UNCOLLAPSE_INFO_MSG: 'Uncollapse to view list',
  IDS_ALERTS_WIDGET_FILTER_ALLALERTS_TITLE: 'All alerts',
  IDS_ALERTS_WIDGET_VIEWALL_BTN_CAPTION: 'View all alerts',
  IDS_ALERTS_WIDGET_ALLGOOD_STATUS: 'All good',
  IDS_ALERTS_WIDGET_EMPTY_NOALERTS_MSG: 'No alerts founded',
  IDS_ALERTS_WIDGET_EMPTY_CLUSTERSHEALTHY_MSG: 'All clusters are healthy',
  IDS_CLUSTERS_STATUS_OK: 'Ok',
  IDS_CLUSTERS_STATUS_WARNING: 'Warning',
  IDS_CLUSTERS_STATUS_CRITICAL: 'Critical',
  IDS_CLUSTERS_STORAGE_USED: 'used',
  IDS_CLUSTERS_TABLE_COLUMN_NAME: 'Cluster name',
  IDS_CLUSTERS_TABLE_COLUMN_COMPANY: 'Company',
  IDS_CLUSTERS_TABLE_COLUMN_BUCKETS: 'Buckets',
  IDS_CLUSTERS_TABLE_COLUMN_NODES: 'Nodes',
  IDS_CLUSTERS_TABLE_COLUMN_VERSION: 'Version',
  IDS_CLUSTERS_TABLE_COLUMN_LASTUPDATE: 'Last update',
  IDS_CLUSTERS_TABLE_COLUMN_STORAGE: 'Storage',
  IDS_CLUSTERS_TABLE_COLUMN_STATUS: 'Status',
  IDS_CLUSTERS_TABLE_COLUMN_ALERTS: 'Alerts',
  IDS_TABLE_SEARCH_PLACEHOLDER: 'Search',
  IDS_TABLE_LASTUPDATE_TITLE: 'Last update:',
  IDS_TABLE_SHOW_SELECTBOX_TEN_ROWS_VALUE: '10 rows',
  IDS_TABLE_SHOW_SELECTBOX_TWANTYFIVE_ROWS_VALUE: '25 rows',
  IDS_TABLE_SHOW_SELECTBOX_FIFTY_ROWS_VALUE: '50 rows',
  IDS_TABLE_SHOW_SELECTBOX_ONEHUNDRED_ROWS_VALUE: '100 rows',
  IDS_TABLE_SHOW_SELECTBOX_PAGE_VALUE: 'page',
  IDS_TABLE_SHOW_SELECTBOX_ALL_VALUE: 'all rows',
};

const locale = (str: string) => {
  return strings[str] || `*${str}`;
}

export default locale;
