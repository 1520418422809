import { ORG_RESPONSE_KEYS } from 'api/models/organizations';
import { ACCOUNT_AVATAR_DATA } from './account';

export const ORG_FILTER_BTN_DATA = {
  ID: ORG_RESPONSE_KEYS.ROWS_FIELD.ID,
  NAME: ORG_RESPONSE_KEYS.ROWS_FIELD.NAME,
  ALERTS_COUNT: ORG_RESPONSE_KEYS.ROWS_FIELD.ALERTS_COUNT,
  ALERTS_COUNT_FIELD: {
    CRITICAL: ORG_RESPONSE_KEYS.ROWS_FIELD.ALERTS_COUNT_FIELD.CRITICAL,
    WARNING: ORG_RESPONSE_KEYS.ROWS_FIELD.ALERTS_COUNT_FIELD.WARNING
  },
  PINNED: 'pinned',
  DISABLED: 'disabled'
} as const;

export const ORG_DETAILS_DATA = {
  ID: ORG_RESPONSE_KEYS.ROWS_FIELD.ID,
  NAME: ORG_RESPONSE_KEYS.ROWS_FIELD.NAME,
  STATS: 'stats',
  STATS_FIELD: {
    TITLE: 'title',
    VALUE: 'value'
  },
  AVATARS: 'avatars',
  AVATARS_FIELD: ACCOUNT_AVATAR_DATA
} as const;
