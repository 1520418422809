export const ACCOUNT_RESPONSE_KEYS = {
  ID: 'id',
  NAME: 'name',
  EMAIL: 'email',
  PERSONALIZATION: 'personalization',
  PERSONALIZATION_FIELD: {
    PINNED_ORG: 'pinnedOrganizations',
    AVATAR_COLOR: 'avatarColor',
    TABLE: 'table',
    TABLE_FIELD: {
      ID: 'id',
      SHOW_PER_PAGE: 'showPerPage',
      COLUMNS: 'columns',
      COLUMNS_FIELD: {
        ID: 'id',
        COLUMN_WIDTH: 'columnWidth'
      }
    }
  }
} as const;

export const ACCOUNT_REQUEST_KEYS = {
  PERSONALIZATION: ACCOUNT_RESPONSE_KEYS.PERSONALIZATION,
  PERSONALIZATION_FIELD: {
    PINNED_ORG: ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.PINNED_ORG,
    TABLE: ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.TABLE
  }
} as const;

export const ACCOUNT_AVATAR_KEYS = {
  ID: ACCOUNT_RESPONSE_KEYS.ID,
  NAME: ACCOUNT_RESPONSE_KEYS.NAME,
  EMAIL: ACCOUNT_RESPONSE_KEYS.EMAIL,
  AVATAR_COLOR: ACCOUNT_RESPONSE_KEYS.PERSONALIZATION_FIELD.AVATAR_COLOR
} as const;
